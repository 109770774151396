<template>
    <div>
            <multiselect
                :class="validateError != '' ? 'box-border-color' : ''"
                :clear-on-select="true"
                :close-on-select="true"
                :deselect-label="''"
                :options="options"
                :multiple="false"
                :placeholder="$t('select')"
                :select-label="''"
                :selected-label="''"
                label="text"
                track-by="value"
                v-model="selected"
            >
                <template slot="selection" slot-scope="{ values, search, isOpen}">
                <span v-if="values.length && !isOpen" class="multiselect__single">
	                {{ translateNSelected(values) }}
                </span>
                </template>
                <span slot="noOptions">{{ $t("no_options") }}</span>
                <span slot="noResult">{{ $t("no_result") }}</span>
            </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";

export default {
    props: {
        value: {
            default: null
        },
        validateError:{
            default:false
        }
    },
    data() {
        return {
            options: [
                {value: "string", text: 'String'},
                {value: 'json', text: 'Json'},
                {value: 'html', text: 'Html'},
                {value: "date", text: 'Date'},
                {value: 'comma', text: 'Comma'},
                {value: 'bool', text: 'Bool'},
                {value: 'semester', text: 'Semester'},
                {value: 'int', text: 'Int'},
                {value: 'file', text: 'File'},
            ]
        }
    },
    computed: {
        selected: {
            get() {
                let result = this.options.find(type => type.value == this.value);
                if (result) return result
                else return this.value
            },
            set(val) {
                if(val==null) return
                this.$emit('input', val.value)
            }
        }
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput
    }
}
</script>
