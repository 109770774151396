<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="6">
                    <ValidationProvider name="key" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('key')">
                            <b-form-input type="text" v-model="formData.key"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <constant-type-selectbox v-model="formData.type"
                                                     :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="value" rules="required" v-slot="{valid, errors}">
                        <b-form-group v-if="formData.type != null"
                                      :label="$t('value')"
                        >
                            <div v-if="formData.type=='string'">
                                <b-form-input type="text" v-model="formData.value"
                                              :state="errors[0] ? false : null"/>
                            </div>
                            <div v-else-if="formData.type=='int'">
                                <b-form-input type="number" v-model="formData.value"
                                              :state="errors[0] ? false : null"/>
                            </div>
                            <div v-else-if="formData.type=='comma'">
                                <b-form-textarea v-model="formData.value"
                                                 rows="3"
                                                 max-rows="6"
                                />
                            </div>
                            <div v-else-if="formData.type=='json'">
                                <b-form-textarea v-model="formData.value"
                                                 rows="3"
                                                 max-rows="6"
                                />
                            </div>
                            <div v-else-if="formData.type=='bool'">
                                <b-form-select v-model="formData.value"
                                               :options="boolOptions"/>
                            </div>
                            <div v-else-if="formData.type=='date'">
                                <select-date v-model="formData.value"
                                             :validation-error="errors[0]"
                                             :is-time="true"
                                />
                            </div>
                            <div v-else-if="formData.type=='semester'">
                                <semesters-selectbox v-model="formData.value"
                                                     :validate-error="errors[0]"/>
                            </div>
                            <div v-else-if="formData.type=='html'">
                                <ckeditor v-model="formData.value"/>
                            </div>
                            <div v-else-if="formData.type=='file'">
                                <b-form-group :label="$t('file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file
                                            v-model="formData.file"
                                            :state="errors[0] ? false : null"
                                            :placeholder="$t('select_file')"
                                            :drop-placeholder="$t('drop_file')"
                                            ref="fileInput"
                                            multiple></b-form-file>
                                        <b-button variant="outline-secondary"
                                                  @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                        </b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </div>
                            <div v-else-if="formData.type=='person'">
                                <staff-auto-complete v-model="formData.value"
                                                     :validate-error="errors[0]"/>
                            </div>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ConstantTypeSelectbox from '@/components/interactive-fields/ConstantTypeSelectbox';
import SelectDate from '@/components/interactive-fields/SelectDate.vue';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox.vue';
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";

// Services
import ConstantService from '@/services/ConstantService';

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        SemestersSelectbox,
        SelectDate,
        ConstantTypeSelectbox,
        StaffAutoComplete,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formData: {
                key: null,
                value: null,
                type: null
            },
            formLoading: false,

            boolOptions: [
                {value: null, text: this.$t('enter_value'), disabled: true},
                {value: 'true', text: this.$t('true')},
                {value: 'false', text: this.$t('false')}
            ]
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                let config={
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                let formData = new FormData()
                if (this.formData.type=='file'){
                    let fileName = this.formData.file[0].name
                    formData.append('file', this.formData.file[0]);
                    this.formData.value=fileName
                }
                formData.append('key', this.formData.key);
                formData.append('value', this.formData.value);
                formData.append('type', this.formData.type);

                this.formLoading = true;
                ConstantService.store(formData,config)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>

